import { mapState, mapActions } from 'vuex'

export default {
    name: 'UserProfile',
    data: () => ({
        errors: {},
        message: '',
        error: false,
        model: [],
        loading: false
    }),
    methods: {
        ...mapActions('user', ['ActionUpdateProfile']),
        save(){
            this.loading = true
            this.errors = {}
            this.error = false
            this.message = ''
            this.ActionUpdateProfile(this.model)
                .then((r) => {
                    this.message = r.message
                })
                .catch((e) => {
                    this.error = true
                    this.errors = e.errors,
                    this.message = e.message
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    created() {
        this.model = this.user;
    },
    computed: {
        ...mapState('auth', ['acesso', 'user']),

        isLoginExternal(){

            if(this.acesso && this.acesso.grupo && this.acesso.grupo.external_login){
                return true;
            }else{
                return false;
            }
        },

    },
}